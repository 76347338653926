<template>
  <div>
    <b-form @submit.prevent="submit">
      <!-- Unidad administrativa -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Unidad administrativa
            <span class="text-danger">*</span>
          </label>
        </template>

        <x-select
          v-model="formValues.unidad_administrativa_id"
          dispatchPath="administrativeUnitModule/getAdministrativeUnits"
          getterListPath="administrativeUnitModule/getAdministrativeUnits"
          :formatListWith="['id', 'nombre']"
        >
          <b-form-select-option :value="null" disabled>
            -- Selecciona una unidad administrativa --
          </b-form-select-option>
        </x-select>
      </b-form-group>

      <!-- Proyecto -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Proyecto
            <span class="text-danger">*</span>
          </label>
        </template>

        <x-select
          v-model="formValues.proyecto_id"
          dispatchPath="proyectoModule/getProyectos"
          getterListPath="proyectoModule/getAllProyectos"
          :formatListWith="['id', 'nombre']"
        >
          <b-form-select-option :value="null" disabled>
            -- Selecciona una unidad administrativa --
          </b-form-select-option>
        </x-select>
      </b-form-group>

      <!-- Partida -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Partida
            <span class="text-danger">*</span>
          </label>
        </template>

        <x-select
          v-model="formValues.partida_id"
          dispatchPath="partidaModule/getAll"
          getterListPath="partidaModule/getPartidas"
          :formatListWith="['id', 'nombre']"
        >
          <b-form-select-option :value="null" disabled>
            -- Selecciona una unidad administrativa --
          </b-form-select-option>
        </x-select>
      </b-form-group>

      <!-- Rubro -->
      <b-form-group>
        <!-- Label -->
        <template #label>
          <label class="small font-weight-bolder">
            Rubro
            <span class="text-danger">*</span>
          </label>
        </template>

        <b-input
          type="text"
          v-model="formValues.rubro"
          :state="Boolean(formValues.rubro)"
        ></b-input>
      </b-form-group>

      <div class="w-100 d-flex justify-content-between">
        <slot name="btn-cancel-place"></slot>

        <b-button @click="submit" variant="success">Crear</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
const formValues = () => ({
  periodo_fiscal_id: 0,
  fuente_financiamiento_id: 0,
  proyecto_id: 0,
  unidad_administrativa_id: 0,
  partida_id: 0,
  rubro: ''
})

export default {
  name: 'Crear',

  props: {
    namespaces: {
      type: String,
      required: true
    },

    fuente: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      formValues: formValues()
    }
  },

  computed: {
    periodo_fiscal () {
      return this.$store.state[this.namespaces].periodo_fiscal
    },
    fuente_financiamiento () {
      return this.$store.state[this.namespaces].fuente_financiamiento
    }
  },

  methods: {
    async submit () {
      this.formValues.fuente_financiamiento_id = this.fuente_financiamiento.id
      this.formValues.periodo_fiscal_id = this.periodo_fiscal.id
      const getval = await this.$store.dispatch(`${this.namespaces}/create`, this.formValues)

      this.$notify(getval, 'Importacion de layout')

      if (!getval.error) {
        this.formValues = formValues()
      }

      this.$emit('on-created')
    }
  }
}
</script>

<style>

</style>
