/**
 * Mixin usado para los componentes de Importe y Edicion
 * del modulo de presupuesto federal
 */
import { EventBus } from '@/EventBus'

export default {
  methods: {
    async $submitObtenerRecursos () {
      this.processingMessage = 'Cargando información, por favor espere...'

      const getval = await this.$store.dispatch(
        `${this.namespaces}/getAll`,
        this.importacion || {}
      )

      //
      // Notifica si ha encontrado un error al usuario
      EventBus.$emit('alert', 'Generación de proyección', getval.message, {
        status: getval.error ? 'danger' : 'success'
      })

      this.errors = {}

      return getval
    }
  }
}
